import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import { ModuleRegistry } from "@ag-grid-community/core";
import { AgGridReact } from "@ag-grid-community/react";
import "@ag-grid-community/styles/ag-grid.css";
import "@ag-grid-community/styles/ag-theme-quartz.css";
import { ColumnsToolPanelModule } from "@ag-grid-enterprise/column-tool-panel";
// import { MenuModule } from "@ag-grid-enterprise/menu";
import { SetFilterModule } from "@ag-grid-enterprise/set-filter";
import { SearchOutlined } from "@ant-design/icons";

import { Button, Col, Dropdown, Input, Menu, message, Modal, Row, Select, Spin } from "antd";
import { Divider, Tabs } from "antd/es";
import TextArea from "antd/es/input/TextArea";
import TabPane from "antd/es/tabs/TabPane";
import axios from "axios";
import parsePhoneNumberFromString from "libphonenumber-js";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import 'react-phone-input-2/lib/bootstrap.css';
import { Link } from "react-router-dom";
import addIcon from "../../assets/add.png";
import closeIcon from "../../assets/clear.png";
import moreIcon from "../../assets/expand_more.png";
import infoIcon from "../../assets/info.png";
import image1 from "../../assets/image1.png";
import moreVertical from "../../assets/more_vert.png";
import API_BASE_URL from "../../env";
import { _status_ACTIVE, _status_DELETED, _status_INACTIVE, _status_PENDING, _status_SUBSCRIPTIONS, _status_TRAIL } from "../../utils/commonConsts";
import { routes_clinic_details } from "../../utils/RoutingConsts";
import CellWithOverflowTooltip from "../CellWithOverflowTooltip/CellWithOverflowTooltip";
import "./Customers.css";
import OffCanvas from "./offcanvas/OffCanvas";

ModuleRegistry.registerModules([
  ClientSideRowModelModule,
  ColumnsToolPanelModule,
  // MenuModule,
  SetFilterModule,
]);

const ActionsRenderer = (props) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const Token = localStorage.getItem("Token");
  const config = {
    headers: {
      Authorization: `Bearer ${Token}`,
    },
  };

  const handleMenuClick = (e) => {
    if (e.target.innerHTML === "Approve") {
      const payload = {};
      axios
        .put(
          `${API_BASE_URL}/api/v1/clinic/updateClinicStatus?id=${props.data.id}&status=${_status_ACTIVE}`,
          payload,
          config
        )
        .then((res) => {
          message.success(res.data?.data);
          let tabActive = props.activeTab === _status_TRAIL ? _status_ACTIVE : props.activeTab;
          props.refreshData(tabActive);
        })
        .catch((err) => {
          message.error(err.response.data.data?.errors[0]);
        });;
    } else if (e.target.innerHTML === "Reject") {
      setModalOpen(true);
    }
  };

  const handleReject = () => {
    const payload = {};
    axios
      .put(
        `${API_BASE_URL}/api/v1/clinic/updateClinicStatus?id=${props.data.id}&status=${_status_INACTIVE}`,
        payload,
        config
      )
      .then((res) => {
        message.success(res.data?.data);
        let tabActive = props.activeTab === _status_TRAIL ? _status_ACTIVE : props.activeTab;
        props.refreshData(tabActive);
      })
      .catch((err) => {
        message.error(err.response.data.data?.errors[0]);
      });;
  }

  const rejectOptions = [
    { value: 'Lack of documents', label: 'Lack of documents' },
    { value: 'Eligibility criteria', label: 'Eligibility criteria' },
    { value: 'Non medical establishment', label: 'Non medical establishment' },
  ]

  const handleChange = (value) => {
    console.log(`selected ${value}`);
  };

  return (
    <div>
      <div className="options">
        <div className="approve" value="approve" onClick={handleMenuClick}>
          Approve
        </div>
        <div className="reject" value="reject" onClick={handleMenuClick}>
          Reject
        </div>
      </div>

      <Modal
        className="reject-modal"
        open={isModalOpen}
        centered
        closable={false}
        footer={false}
      >
        <div className="modal-body">
          <div className="close" onClick={() => setModalOpen(false)}><img src={closeIcon} alt="Close" /></div>
          <div className="header">
            Reject Signup
          </div>
          <div className="body">
            <div className="modal-field">
              <label>Reason for Rejection
                <span className="asterisk">*</span>
              </label>
              <Select
                className="select-dropdown"
                placeholder="Select Reason"
                onChange={handleChange}
                options={rejectOptions}
                suffixIcon={<img src={moreIcon} alt="More Icon" />}
              />
            </div>
            <div className="modal-field">
              <label>Description
                <span className="asterisk">*</span>
              </label>
              <TextArea />
            </div>
          </div>
          <div className="buttons-container">
            <Button className="cancel" onClick={() => setModalOpen(false)}>Cancel</Button>
            <Button className="reject" onClick={handleReject}>Reject </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

const Customers = () => {
  const Token = localStorage.getItem("Token");
  const gridRef = useRef(null);
  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);
  const [rowData, setRowData] = useState();
  const [activeTab, setActiveTab] = useState(_status_PENDING);
  const [show, setShow] = useState(false);
  const [openDeleteModal, setOpenConfirmationModal] = useState(false);
  const [clinicId, setClinicId] = useState();
  const [clinicStatusOption, setClinicStatusOption] = useState();
  const handleOpen = () => {
    setShow(true);
  };
  const [loading, setLoading] = useState(false);
  const config = {
    headers: {
      Authorization: `Bearer ${Token}`,
    },
  };

  useEffect(() => {
    if (!show) {
      if (activeTab === _status_TRAIL) {
        refreshData(_status_ACTIVE)
      }
      else {
        refreshData(activeTab);
      }
    }
  }, [activeTab, show]);

  const refreshData = (status) => {
    setRowData([]);
    setLoading(true);

    axios
      .get(`${API_BASE_URL}/api/v1/clinic/clinics?status=${status}`, config)
      .then((res) => {
        const updatedData = res.data.data.map((item) => {
          if (item.trial_access === undefined) {
            return { ...item, trial_access: 'Login pending' };
          }
          return item;
        });

        setRowData(updatedData);
        setLoading(false);
      })
      .catch((err) => {
        message.error(err.response.data.data?.errors[0]);
        setLoading(false);
      });
  };

  const formatPhoneNumber = (phoneNumber) => {
    // Detect the country code based on the phone number prefix
    const countryCode = phoneNumber.startsWith('91') ? 'IN' : 'US';

    // Parse the phone number with the detected country code
    const parsedNumber = parsePhoneNumberFromString(phoneNumber, countryCode);

    // Format the phone number in international format with the +{countrycode}
    return parsedNumber ? parsedNumber.formatInternational() : phoneNumber;
  };

  const handleClinicStatusChange = (id, key) => {
    setOpenConfirmationModal(true);
    setClinicId(id);
    setClinicStatusOption(key);
  }

  const handleDeleteClinic = () => {
    axios
      .delete(
        `${API_BASE_URL}/api/v1/clinic/${clinicId}`, config
      )
      .then((res) => {
        message.success(res.data);
        setOpenConfirmationModal(false);
        let tabActive = activeTab === _status_TRAIL ? _status_ACTIVE : activeTab;
        refreshData(tabActive);
      }
      )
      .catch(() => {
        message.error('Failed to delete clinic');
        setOpenConfirmationModal(false);
      })
  }

  const handleRevokeClinic = () => {
    const payload = {};
    axios
      .put(
        `${API_BASE_URL}/api/v1/clinic/updateClinicStatus?id=${clinicId}&status=${_status_INACTIVE}`,
        payload,
        config
      )
      .then((res) => {
        message.success(res.data.data);
        setOpenConfirmationModal(false);
        let tabActive = activeTab === _status_TRAIL ? _status_ACTIVE : activeTab;
        refreshData(tabActive);
      })
      .catch(err => {
        message.error('Failed to revoke clinic');
        setOpenConfirmationModal(false);
      });
  };

  const handleDropdownMenuClick = (id, key) => {
    const payload = {};
    axios
      .put(
        `${API_BASE_URL}/api/v1/clinic/updateClinicStatus?id=${id}&status=${key}`,
        payload,
        config
      )
      .then((res) => {
        if (key === _status_DELETED) {
          message.success(' Clinic Deleted Successfully');
        }
        let tabActive = activeTab === _status_TRAIL ? _status_ACTIVE : activeTab;
        refreshData(tabActive);
      });
  };

  const copyToClipboard = (email) => {
    navigator.clipboard.writeText(email)
      .then(() => {
        message.success("Email copied to clipboard!");
      })
      .catch(() => {
        message.error("Failed to copy email!");
      });
  };

  const gridOptions = {
    suppressHorizontalScroll: false, // Enable horizontal scrolling
    overlayNoRowsTemplate: '<span class="custom-no-rows">No clinics to display</span>',
  }

  const isNumeric = (input) => {
    if (input === '1' || input === '2' || input === '3' || input === '4' || input === '5' || input === '6' || input === '7') {
      return true;
    }
    else {
      return false;
    }
  }

  const columnDefs = [
    {
      field: "clinic_name",
      headerName: "CLINIC NAME",
      cellRenderer: (params) => {
        const { clinic_name, id } = params.data;
        return (
          <Row className="clinic-row-data-element">
            <Col className="pic-col">
              <img
                className="clinic-profile-pic"
                src={image1}
                alt="Profile Picture"
              />
            </Col>
            <Col className="clinic-name">
              <Link to={`${routes_clinic_details}/${id}`}><CellWithOverflowTooltip value={clinic_name} /></Link>
            </Col>
          </Row>
        );
      },
      // flex: 2,
      minWidth: 240
    },
    {
      field: "contact_info",
      headerName: "POINT OF CONTACT",
      cellRenderer: (params) => {
        const { first_name, last_name, email } = params.data;

        return (
          <div className="row-data-element">
            <div className="full-name"><CellWithOverflowTooltip value={`${first_name} ${last_name}`} /></div>
            <div className="email" onClick={(() => copyToClipboard(email))}><CellWithOverflowTooltip value={email} /></div>
            {/* <div className="email">{email}</div> */}
          </div>
        );
      },
      // flex: 2,
      minWidth: 240
    },
    {
      field: "phone_number",
      headerName: "PHONE NUMBER",
      cellRenderer: (params) => {
        const { phone_number } = params.data;
        return (
          <div className="row-data-element">
            <div className="phone">{formatPhoneNumber(phone_number)}</div>
          </div>
        );
      },
      // flex: 1.5,
      minWidth: 200
    },
    {
      field: "created_at",
      headerName: "CREATED DATE",
      cellRenderer: (params) => {
        const { created_at } = params.data;
        const created_date = moment.utc(created_at).local().format("DD MMM YYYY, hh:mm A");
        return (
          <div className="row-data-element">
            <div className="created-date">{created_date}</div>
          </div>
        );
      },
      // flex: 1.5,
      minWidth: 240
    },
    {
      field: "status",
      headerName: "ACCOUNT STATUS",
      cellRenderer: (params) => {
        const { status } = params.data;
        let statusClass = "";

        if (status === "PENDING") {
          statusClass = "status-pending"; // Pending tab
        } else {
          statusClass = "status-active";  // Trial and subscriptions tab
        }

        return (
          <div className="row-data-element">
            <div className={`status ${statusClass}`}>
              {status === "PENDING" ? "PENDING" : "APPROVED"}
            </div>
          </div>
        );
      },
      // flex: 1.25,
      minWidth: 180
    },
    ...(activeTab === _status_PENDING
      ? [
        {
          field: "actions",
          headerName: "ACTIONS",
          cellRenderer: (params) => {
            const { id } = params.data;
            return (
              <div className="actions-list">
                <ActionsRenderer {...params} refreshData={refreshData} activeTab={activeTab} />
                <Dropdown
                  overlay={
                    <Menu>
                      <Menu.Item key="view"><Link to={`${routes_clinic_details}/${id}`}>View Details</Link></Menu.Item>
                      <Menu.Item
                        key="delete"
                        onClick={() =>
                          handleClinicStatusChange(id, 'Delete')
                        }
                      >
                        Delete Clinic
                      </Menu.Item>
                    </Menu>
                  }
                  trigger={["click"]}
                >
                  <img src={moreVertical} alt="More" className="more" />
                </Dropdown>
              </div>
            );
          },
          pinned: 'right',  // This fixes the column to the right side
          suppressMovable: true,  // Prevent this column from being draggable
          lockPosition: true,
          sortable: false, // Optional: Disable sorting if not needed
          filter: false,
          resizable: false,
          flex: 1.5,
          minWidth: 230
        }
      ] : []),
    ...(activeTab === _status_TRAIL
      ? [
        {
          field: "trial_access",
          headerName: "TRIAL ACCESS",
          filter: true,
          cellRenderer: (params) => {
            const { trial_access } = params.data;

            return (
              <div className="row-data-element">
                <div
                  className={trial_access === 'expired' ? "expired" : `${trial_access === 'Login pending' ? "login-pending" : 'trial-days'}`}
                >
                  {isNumeric(trial_access) ? `${trial_access} days` : trial_access}
                </div>
              </div>
            );
          },
          flex: 1.5,
          minWidth: 180
        },
        {
          field: "actions",
          headerName: "ACTIONS",
          cellRenderer: (params) => {
            const { id } = params.data;
            return (
              <div>
                <Dropdown
                  overlay={
                    <Menu>
                      <Menu.Item
                        key="revoke"
                        onClick={() =>
                          handleClinicStatusChange(id, 'Revoke')
                        }
                      >
                        Revoke Approval
                      </Menu.Item>
                      <Menu.Item key="view"><Link to={`${routes_clinic_details}/${id}`}>View Details</Link></Menu.Item>
                      <Menu.Item
                        key="delete"
                        onClick={() =>
                          handleClinicStatusChange(id, 'Delete')
                        }
                      >
                        Delete Clinic
                      </Menu.Item>
                    </Menu>
                  }
                  trigger={["click"]}
                >
                  <img src={moreVertical} alt="More" className="more" />
                </Dropdown>
              </div>
            );
          },
          pinned: 'right',  // This fixes the column to the right side
          suppressMovable: true,  // Prevent this column from being draggable
          lockPosition: true,
          sortable: false, // Optional: Disable sorting if not needed
          filter: false,
          resizable: false,
          flex: 0.75,
          maxWidth: 120

        }
      ]
      : []),
    ...(activeTab === _status_SUBSCRIPTIONS
      ? [
        {
          field: "plan",
          headerName: "SUBSCRIPTIONS",
          filter: true,
          cellRenderer: (params) => {
            const { plan } = params.data;
            return (
              <div className="row-data-element">
                <div className="plan">{plan}</div>
              </div>
            );
          },
          flex: 1.5,
          minWidth: 150
        },
        {
          field: "actions",
          headerName: "ACTIONS",
          cellRenderer: (params) => {
            <CellWithOverflowTooltip value={params.value} />;
            const { id } = params.data;
            return (
              <div className="row-data-element">
                <Dropdown
                  overlay={
                    <Menu>
                      <Menu.Item
                        key="revoke"
                        onClick={() =>
                          handleClinicStatusChange(id, 'Revoke')
                        }
                      >
                        Revoke Approval
                      </Menu.Item>
                      <Menu.Item
                        key="renew"
                        onClick={() =>
                          handleDropdownMenuClick(id, _status_ACTIVE)
                        }
                      >
                        Renew
                      </Menu.Item>
                      <Menu.Item key="view"><Link to={`${routes_clinic_details}/${id}`}>View Details</Link></Menu.Item>
                      <Menu.Item
                        key="delete"
                        onClick={() =>
                          handleClinicStatusChange(id, 'Delete')
                        }
                      >
                        Delete Clinic
                      </Menu.Item>
                    </Menu>
                  }
                  trigger={["click"]}
                >
                  <img src={moreVertical} alt="More" className="more" />
                </Dropdown>
              </div>
            );
          },
          pinned: 'right',  // This fixes the column to the right side
          suppressMovable: true,  // Prevent this column from being draggable
          lockPosition: true,
          sortable: false, // Optional: Disable sorting if not needed
          filter: false,
          resizable: false,
          flex: 0.75,
          maxWidth: 100
        }
      ]
      : []),
  ];

  const defaultColDef = useMemo(() => {
    return {
      flex: 2,
      resizable: true, // Enable column resizing
      autoHeight: true,
      wrapText: true
    };
  }, []);

  const rowHeight = 80;

  const rowSelection = useMemo(() => {
    return {
      mode: "multiRow",
    };
  }, []);


  const onFirstDataRendered = (params) => {
    params.api.paginationGoToPage(0); // Optional, but ensures the grid shows the first page
  };

  const onFilterTextBoxChanged = useCallback(() => {
    gridRef.current.api.setGridOption(
      "quickFilterText",
      document.getElementById("filter-text-box").value
    );
  }, []);

  const onTabChange = async (key) => {
    setActiveTab(key); // Set active tab

    setTimeout(() => {
      if (key === _status_TRAIL) {
        refreshData(_status_ACTIVE)
      }
      else {
        refreshData(key);
      }
    }, 500);
  };


  return (
    <div className="customers-container">
      <Helmet>
        <title>Customer Dashboard</title>
      </Helmet>
      <div className="data-container">
        <div className="data-header">
          <Tabs
            defaultActiveKey={_status_PENDING}
            onChange={onTabChange}
            className="custom-tabs"
          >
            <TabPane tab="Signup Pending" key={_status_PENDING} />
            <TabPane tab="Trial" key={_status_TRAIL} />
            <TabPane tab="Subscribed" key={_status_SUBSCRIPTIONS} />
          </Tabs>
          <div className="search-container">
            <Button className="add-clinic" onClick={handleOpen}>
              <img src={addIcon} />Customer
            </Button>
            <Input
              className="search-box"
              type="text"
              id="filter-text-box"
              placeholder="Search"
              onInput={onFilterTextBoxChanged}
              suffix={<SearchOutlined />}
            />
          </div>
        </div>
        <Divider plain></Divider>
        {loading && (
          <Spin className="spinner" />

        )}
        {!loading && (
          <div style={gridStyle} className="ag-theme-quartz">
            <AgGridReact
              ref={gridRef}
              rowData={rowData}
              columnDefs={columnDefs}
              defaultColDef={defaultColDef}
              pagination={true}
              paginationPageSize={10}
              paginationPageSizeSelector={[10, 25, 50, 100]}
              onFirstDataRendered={onFirstDataRendered}
              tooltipShowDelay={0}
              rowSelection={rowSelection}
              rowHeight={rowHeight}
              gridOptions={gridOptions}
            />
          </div>
        )}
      </div>

      <OffCanvas show={show} setShow={setShow} mode={'add'} />

      <Modal
        className="reject-modal"
        open={openDeleteModal}
        centered
        closable={false}
        footer={false}
      >
        <div className="modal-body">
          <div className="close" onClick={() => setOpenConfirmationModal(false)}><img src={closeIcon} alt="Close" /></div>
          <div className="header">
            <img src={infoIcon} alt="info" className="info-icon" />
            {clinicStatusOption === 'Delete' ? 'Delete Customer' : 'Revoke Approval'}
          </div>
          <div className="body">
            <span className="description">You are trying to {clinicStatusOption === 'Delete' ? 'delete ' : 'revoke approval for '}a customer,
              this will remove access to clinic data for all user roles onboarded.
              Are you sure you want to continue?
            </span>

          </div>
          <div className="buttons-container">
            <Button className="cancel" onClick={() => setOpenConfirmationModal(false)}>No</Button>
            <Button className="reject" onClick={clinicStatusOption === 'Delete' ? handleDeleteClinic : handleRevokeClinic}>Yes</Button>
          </div>
        </div>
      </Modal>
    </div >
  );
};

export default Customers;