import { Helmet } from "react-helmet";
import './Users.css';

const Users = () => {
  return (
    <div>
      <Helmet>
        <title>Users Dashboard</title>
      </Helmet>
      <div className="users-container">
        <h3>Users Module</h3>
      </div>
    </div>
  );
};

export default Users;
