import { CopyOutlined, UserOutlined } from "@ant-design/icons";
import { Col, Divider, message, Row, Spin, Tooltip } from 'antd';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import clinicLogo from '../../../assets/clinic-logo.png';
import editIcon from '../../../assets/edit-icon.png';
import emailIcon from "../../../assets/mail_outline.png";
import phoneIcon from "../../../assets/phone.png";
import API_BASE_URL from '../../../env';
import CellWithOverflowTooltip from "../../CellWithOverflowTooltip/CellWithOverflowTooltip";
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import './ClinicDetails.css';
import OffCanvas from "../offcanvas/OffCanvas";

const ClinicDetails = () => {
    const { clinicId } = useParams();
    const [clinicData, setClinicData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [show, setShow] = useState(false);
    const Token = localStorage.getItem('Token');
    const config = {
        headers: {
            Authorization: `Bearer ${Token}`,
        },
    };

    useEffect(() => {
        if (!show) {
            axios
                .get(`${API_BASE_URL}/api/v1/clinic/${clinicId}`, config)
                .then((res) => {
                    setClinicData(res.data.data);
                    setLoading(false);
                })
                .catch((err) => {
                    message.error('Error fetching clinic details');
                    setLoading(false);
                });
        }
    }, [clinicId,show]);

    const handleOpen = () => {
        setShow(true);
    };

    const copyToClipboard = (email) => {
        navigator.clipboard.writeText(email)
            .then(() => {
                message.success("Email copied to clipboard!");
            })
            .catch(() => {
                message.error("Failed to copy email!");
            });
    };

    const formatPhoneNumber = (phoneNumber) => {
        // Detect the country code based on the phone number prefix
        const countryCode = phoneNumber?.startsWith('91') ? 'IN' : 'US';

        // Parse the phone number with the detected country code
        const parsedNumber = parsePhoneNumberFromString(phoneNumber, countryCode);

        // Format the phone number in international format with the +{countrycode}
        return parsedNumber ? parsedNumber.formatInternational() : phoneNumber;
    };

    if (loading) {
        return <Spin className="spinner" />;
    }

    return (
        <div className='clinic-details-container'>
            <Col className='logo-container'>
                <div className='logo'>
                    <img src={clinicLogo} alt='Clinic Logo' />
                </div>
                <div className='name-container'>
                    <Row className='clinic-name-row'>
                        <span className='clinic-name'><CellWithOverflowTooltip value={clinicData?.clinic_name}/></span>
                    </Row>
                    <Row className='data-field'>
                        <label>POC</label>
                        <span className="user-name"><UserOutlined /><CellWithOverflowTooltip value={`${clinicData?.first_name} ${clinicData?.last_name}`} /></span>
                    </Row>
                </div>
            </Col>
            <Col className='information-container'>
                <Row className='information-row'>
                    <Col className='data-field'>
                        <label>contact information</label>
                        <span className="phone"><img src={phoneIcon} />{formatPhoneNumber(clinicData?.phone_number)}</span>
                        <span className='email'><img src={emailIcon} />
                            <div className="email-field">
                                <CellWithOverflowTooltip value={clinicData?.email} />
                                <Tooltip title="Copy Email">
                                    <CopyOutlined
                                        className="copy-icon"
                                        onClick={() => copyToClipboard(clinicData?.email)} // Trigger copy on click
                                    />
                                </Tooltip>
                            </div>
                        </span>
                    </Col>
                    <Col className='data-field'>
                        <label>created on</label>
                        <span>{clinicData?.created_at}</span>
                    </Col>
                    <Col className='data-field'>
                        <label>registration type</label>
                        <span>{clinicData?.register_type ? clinicData?.register_type : "Claims"}</span>
                    </Col>
                    {/* {clinicData?.register_type === 'Claims' &&
                        <Col className='data-field'>
                            <label>pms</label>
                            <span>{clinicData?.pms_name ? clinicData?.pms_name : 'SIKKA'}</span>
                        </Col>
                    } */}
                    <Col className='data-field'>
                            <label>pms</label>
                            <span>{clinicData?.pms_name ? clinicData?.pms_name : 'SIKKA'}</span>
                        </Col>
                    <Col className='data-field'>
                        <label>status</label>
                        <span className={clinicData?.status === 'ACTIVE' ? 'active' : 'pending'}>{clinicData?.status === 'ACTIVE' ? 'APPROVED' : clinicData?.status}</span>
                    </Col>
                </Row>
                <Divider />
                <Row className='information-row'>
                    <span className='row-header'>Establishment Information</span>
                </Row>
                <Row className='information-row'>
                    <Col className='data-field'>
                        <label>address</label>
                        <span className='address'>134 Broadway Avenue
                            NY  WA USA 11691</span>
                    </Col>
                    <Col className='data-field'>
                        <label>BRANCH name</label>
                        <span>BROADWAY</span>
                    </Col>
                    <Col className='data-field'>
                        <label>LOCALITY</label>
                        <span>Broadway Avenue</span>
                    </Col>
                    <Col className='data-field'>
                        <label>license number</label>
                        <span>116789</span>
                    </Col>
                    <Col className='data-field'>
                        <label>no. of employees/staffs</label>
                        <span>5</span>
                    </Col>
                </Row>
                <Row className='information-row'>
                    <span className='row-header'>Business Information</span>
                </Row>
                <Row className='information-row'>
                    <Col className='data-field'>
                        <label>business turnover</label>
                        <span>$ 15,000.00</span>
                    </Col>
                    <Col className='data-field'>
                        <label>sales target (approx)</label>
                        <span>$ 20,000.00</span>
                    </Col>
                    <Col className='data-field'>
                        <label>avg. no. of patients / month</label>
                        <span>220</span>
                    </Col>
                </Row>
                <Row className='information-row'>
                    <span className='row-header'>Operating Hours</span>
                </Row>
                <Row className='information-row'>
                    <Col className='data-field'>
                        <label>starts at</label>
                        <span>10:00 AM</span>
                    </Col>
                    <Col className='data-field'>
                        <label>ends at</label>
                        <span>08:00 PM</span>
                    </Col>
                </Row>
            </Col>
            <Col className='edit' onClick={handleOpen}>
                <img src={editIcon} alt='Edit' />
            </Col>

            {show && <OffCanvas show={show} setShow={setShow} mode={'edit'} data={clinicData} />}
        </div>
    );
};

export default ClinicDetails;
