import React from "react";
import { Helmet } from "react-helmet";
import './Subscriptions.css';

const Subscriptions = () => {
  return (
    <div>
      <Helmet>
        <title>Subscriptions Dashboard</title>
      </Helmet>
      <div className="subscriptions-container">
        <h3>Subscriptions Module</h3>
      </div>
      
    </div>
  );
};

export default Subscriptions;
