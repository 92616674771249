import { Button, Col, Form, Input, message, Radio, Row, Select, Spin } from "antd";
import axios from "axios";
import { useEffect, useState } from "react";
import { Offcanvas } from "react-bootstrap";
import PhoneInput from "react-phone-input-2";
import API_BASE_URL from "../../../env";


const OffCanvas = ({ show, setShow, mode, data }) => {
    const [addClinicForm] = Form.useForm();
    const [radioValue, setRadioValue] = useState('');
    const [isPMSEnabled, setIsPMSEnabled] = useState(false);
    const [spinner, setSpinner] = useState(false);

    useEffect(() => {
        if (mode === 'edit') {
            addClinicForm.setFieldsValue({
                register_type: `${data?.register_type}`,
                pms_name: `${data?.pms_name}`,
                first_name: `${data?.first_name}`,
                last_name: `${data?.last_name}`,
                clinic_name: `${data?.clinic_name}`,
                email: `${data?.email}`,
                phone_number: `${data?.phone_number}`
            });
            setIsPMSEnabled(data?.register_type === "Claims" ? true : false);
        }
    }, [data])

    const handleClose = () => {
        setShow(false);
        addClinicForm.resetFields();
    };

    const handleClinicForm = async (values) => {
        setSpinner(true);
        const payload = {
            register_type: 'Claims',
            first_name: values.first_name,
            last_name: values.last_name,
            clinic_name: values.clinic_name,
            email: values.email,
            phone_number: values.phone_number,
            terms_accepted: true,
            is_register_by: data?.status === 'PENDING' ? "Self" : "ivoryAdmin",
        };

        if (mode === 'add') {
            await axios
                .post(`${API_BASE_URL}/api/v1/clinic/clinic-sign-up`, payload)
                .then(async (res) => {
                    setSpinner(false);
                    message.success("Invite sent successfully");
                    handleClose();
                })
                .catch((err) => {
                    // message.error(err.response.data.errors);
                    setSpinner(false);
                    message.error(err.response.data.data.errors[0]);
                });
        } else {
            let Token = localStorage.getItem("Token");
            const config = {
                headers: {
                    Authorization: `Bearer ${Token}`,
                },
            };

            await axios
                .put(`${API_BASE_URL}/api/v1/clinic/${data?.id}`, payload, config)
                .then(async (res) => {
                    setSpinner(false);
                    message.success("Details Updated successfully");
                    handleClose();
                })
                .catch((err) => {
                    // message.error(err.response.data.errors);
                    setSpinner(false);
                    message.error(err.response.data.data.errors[0]);
                });
        }
    }

    const onRadioChange = (e) => {
        setRadioValue(e.target.value);
        setIsPMSEnabled(e.target.value === "Claims" ? true : false);
    };


    return (
        <Offcanvas show={show} onHide={handleClose} placement="end" className='add-clinic-canvas'>

            <Offcanvas.Header closeButton>
                <Offcanvas.Title>{mode === 'add' ? 'Add' : 'Edit'} Customer</Offcanvas.Title>
            </Offcanvas.Header>

            <Offcanvas.Body>
                <Spin spinning={spinner} tip={mode === 'add' ? 'Adding...' : 'Updating...'}>
                    <Form
                        form={addClinicForm}
                        className="clinic-form"
                        onFinish={handleClinicForm}
                    >
                        <div className="clinic-form-container">
                            <div className="clinic-form-div">
                                {/* <Row className="clinic-form-field">
                                    <span className="field-header-text">Registration Type
                                        <span className="asterisk">*</span>
                                    </span>
                                    <Form.Item
                                        name="register_type"
                                        rules={[
                                            {
                                                required: true,
                                                message: "Please choose Registration Type!",
                                            },
                                        ]}
                                    >
                                        <Radio.Group
                                            onChange={onRadioChange}
                                            value={radioValue}
                                        >
                                            <Radio value={'Claims'}><span className="field-radio-text">Claims</span></Radio>
                                            <Radio value={'PMS + Claims'}><span className="field-radio-text">PMS + Claims</span></Radio>
                                        </Radio.Group>
                                    </Form.Item>
                                </Row>
                                {isPMSEnabled &&
                                    <Row className="clinic-form-field" >
                                        <span className="field-header-text">Preferred Practice Management System
                                            <span className="asterisk">*</span>
                                        </span>
                                        <Form.Item
                                            name="pms_name"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Please select the PMS!",
                                                },
                                            ]}
                                        >
                                            <Select
                                                className="clinic-form-select"
                                                showSearch
                                                placeholder="Type or Select"
                                                options={[
                                                    {
                                                        value: 'Kolla',
                                                        label: 'KOLLA',
                                                    },
                                                    {
                                                        value: 'Sikka',
                                                        label: 'SIKKA',
                                                    },
                                                ]}
                                            />
                                        </Form.Item>
                                    </Row>} */}
                                <Row className="clinic-form-field">
                                    <span className="field-header-text">First Name
                                        <span className="asterisk">*</span>
                                    </span>
                                    <Form.Item
                                        name="first_name"
                                        rules={[
                                            {
                                                required: true,
                                                message: "Please enter the first name!",
                                            },
                                        ]}
                                    >
                                        <Input className="clinic-form-input" />
                                    </Form.Item>
                                </Row>
                                <Row className="clinic-form-field">
                                    <span className="field-header-text">Last Name
                                        <span className="asterisk">*</span>
                                    </span>
                                    <Form.Item
                                        name="last_name"
                                        rules={[
                                            {
                                                required: true,
                                                message: "Please enter the last name!",
                                            },
                                        ]}
                                    >
                                        <Input className="clinic-form-input" />
                                    </Form.Item>
                                </Row>
                                <Row className="clinic-form-field">
                                    <span className="field-header-text">Clinic Name
                                        <span className="asterisk">*</span>
                                    </span>
                                    <Form.Item
                                        name="clinic_name"
                                        rules={[
                                            {
                                                required: true,
                                                message: "Please enter the clinic name!",
                                            },
                                        ]}
                                    >
                                        <Input className="clinic-form-input" disabled={mode === 'edit'} />
                                    </Form.Item>
                                </Row>
                                <Row className="clinic-form-field">
                                    <span className="field-header-text">Work Email
                                        <span className="asterisk">*</span>
                                    </span>
                                    <Form.Item
                                        name="email"
                                        rules={[
                                            {
                                                required: true,
                                                message: "Please enter the email!",
                                            },
                                        ]}
                                    >
                                        <Input className="clinic-form-input" disabled={mode === 'edit'}/>
                                    </Form.Item>
                                </Row>
                                <Row className="clinic-form-field">
                                    <span className="field-header-text">Phone Number
                                        <span className="asterisk">*</span>
                                    </span>
                                    <Form.Item
                                        name="phone_number"
                                        rules={[
                                            {
                                                required: true,
                                                message: "Please enter phone number",
                                            },
                                            {
                                                pattern: /^[1-9]\d*$/,
                                                message: "Phone number cannot start with a zero",
                                            },
                                            // { validator: validatePhoneNumber },
                                        ]}
                                    >
                                        <PhoneInput
                                            country={'us'}
                                            onlyCountries={['us', 'in']}
                                            inputClass="form-control"
                                        />
                                    </Form.Item>

                                </Row>
                            </div>
                        </div>
                        <div className="button-container">
                            <Button className="cancel-button" onClick={handleClose}>
                                Cancel
                            </Button>
                            <Button className="add-button" htmlType="submit" hidden={mode === 'add'}>
                                Save
                            </Button>
                            <Button className="add-button" htmlType="submit" hidden={mode === 'edit'}>
                                Add
                            </Button>
                        </div>
                    </Form>
                </Spin>
            </Offcanvas.Body>
        </Offcanvas>
    )
}

export default OffCanvas;