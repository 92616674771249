import { Button, Col, message, notification, Row, Spin } from "antd";
import React, { useEffect, useState } from "react";
import ivorylogo from "../../assets/ivorylogo.png";
import resendmail from "../../assets/resendmail.png";
import { useNavigate } from "react-router-dom";
import { UserAuth } from "../../context_api/ContextApi";
import axios from "axios";
import API_BASE_URL from "../../env";
import { CheckCircleFilled } from "@ant-design/icons";
import './Resend.css';

const Resend = () => {
    const email = localStorage.getItem("email");
    const navigate = useNavigate();
    const { spinner, setSpinner } = UserAuth();

    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const [timer, setTimer] = useState(60); // 60-second timer

    const handleResendEmail = async (values) => {
        setSpinner(true);
        const payload = {};
        const placement = "bottom";
        setIsButtonDisabled(true);
        setTimer(60); // Reset timer to 60 seconds

        await axios
            .post(
                `${API_BASE_URL}/api/v1/clinic/resend-email?email=${email}`,
                payload
            )
            .then(async (res) => {
                setTimeout(() => {
                    setSpinner(false);
                    api.info({
                        message: `A New Verification link has been sent to your email`,
                        placement,
                        icon: <CheckCircleFilled style={{ color: "#71BF8C" }} />,
                        pauseOnHover: true,
                        // duration: 0,
                        style: {
                            width: "28.854166666666668vw",
                            // height: "10.531803962460897vh",
                            bordeRadius: 10,
                            border: "0px solid #FF0000",
                            display: "flex",
                            alignItems: "center",
                            boxShadow: "0px 0px 4px 0px #27AE60",
                        },
                    });
                    // localStorage.setItem("email", values.email);
                    // navigate("/resend");
                    // message.success("Successfully registered");
                    // form.resetFields();
                }, 500);
                // setSpinner(false);
            })
            .catch((err) => {
                // setSpinner(false);
                message.error(
                    err.response.data.data.errors && err.response.data.data.errors
                );
            });
    };
    const [api, contextHolder] = notification.useNotification();
    const openNotification = () => {
        const placement = "bottom";
        api.info({
            message: `Verification link has been sent to your email`,
            // duration: 0,
            placement,
            pauseOnHover: true,
            icon: <CheckCircleFilled style={{ color: "#71BF8C" }} />,
            style: {
                width: "28.854166666666668vw",
                // height: "10.531803962460897vh",
                bordeRadius: 10,
                border: "0px solid #FF0000",
                display: "flex",
                alignItems: "center",
                boxShadow: "0px 0px 4px 0px #27AE60",
            },
        });
    };

    useEffect(() => {
        openNotification();
    }, []);

    useEffect(() => {
        let interval;
        if (isButtonDisabled && timer > 0) {
            interval = setInterval(() => {
                setTimer((prevTimer) => prevTimer - 1);
            }, 1000);
        }

        if (timer === 0) {
            setIsButtonDisabled(false);
            clearInterval(interval);
        }

        return () => clearInterval(interval);
    }, [isButtonDisabled, timer]);

    return (
        <Spin spinning={spinner} tip="Sending...">
            <div
            className="d-flex jc-center ai-center"
            style={{ width: "100vw", height: "100vh" }}
        >
            {contextHolder}
            <div className="resend-main-div">
                <Row className="w-100 d-flex jc-center">
                    <img alt="" src={ivorylogo} className="ivorylogo" />
                </Row>
                <Row className="w-100 d-flex jc-center">
                    <span className="check-your-inbox">Check your inbox</span>
                </Row>
                <Row className="w-100 d-flex jc-center">
                    <span className="click-on-link">
                        Click on the link sent to{" "}
                        <span className="click-on-link-email">{email}</span> to finish your
                        account setup
                    </span>
                </Row>
                <Row className="w-100 d-flex jc-center">
                    <span className="no-email-text">
                        No email in your inbox or spam folder?
                    </span>
                </Row>
                <Row className="w-100 d-flex jc-center">
                    <Button
                        className="resend-btn"
                        onClick={handleResendEmail}
                        disabled={isButtonDisabled}
                    >
                        Resend Email
                    </Button>
                </Row>
                <Row className="w-100 d-flex jc-center">
                    <span
                        className="try-resend-text"
                        style={{ display: !isButtonDisabled && "none" }}
                    >
                        Try Resend email in{" "}
                        <span className="countdown">{isButtonDisabled && `${timer}s`}</span>
                    </span>
                </Row>
                <Row className="w-100 d-flex jc-center">
                    <Col span={5}></Col>
                    <Col span={14} className="d-flex flex-col ai-center jc-evenly">
                        <span className="wrong-address-text">
                            Wrong address?{" "}
                            <span
                                className="log-out-text"
                                onClick={() => {
                                    setTimeout(() => {
                                        setSpinner(true);
                                        navigate(-1);
                                        setIsButtonDisabled(false);
                                        localStorage.clear();
                                        setSpinner(false);
                                    }, 300);
                                }}
                            >
                                Go Back
                            </span>{" "}
                            to try with a different email.
                        </span>
                        <span className="wrong-address-text"></span>
                    </Col>
                    <Col span={5} className="d-flex jc-end">
                        <img alt="" src={resendmail} className="resendmail" />
                    </Col>
                </Row>
            </div>
        </div>
        </Spin>
    );
};

export default Resend;
