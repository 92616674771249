import { CCarousel, CCarouselCaption, CCarouselItem, CImage } from "@coreui/react";
import { Button, Col, Form, Input, message, Row, Spin } from "antd";
import axios from "axios";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import background from "../../assets/login-bg.png";
import ivoryLogo from "../../assets/ivorylogo.png";
import API_BASE_URL from "../../env";
import { routes_resend } from "../../utils/RoutingConsts";
import { common_constants_email } from "../../utils/commonConsts";
import './ForgotPassword.css';
import { Helmet } from "react-helmet";

const ForgotPassword = () => {
    const navigate = useNavigate();
    const [forgotPasswordForm] = Form.useForm();
    const [spin, setSpin] = useState(false);

    const handleSendEmailButton = async (values) => {
        setSpin(true);
        const payload = {
            email: values.email
        }

        await axios
            .post(`${API_BASE_URL}/api/v1/cPanelUsers/forgot-password`, payload)
            .then((res) => {
                // message.success('Mail Sent Successfully');
                localStorage.setItem(common_constants_email, values.email);
                navigate(routes_resend);
                setSpin(false);
            })
            .catch((err) => {
                message.error(err.response.data.data?.errors[0]);
                setSpin(false);
            })
    }

    return (
        <Spin spinning={spin} tip="Sending...">
            <Row
                className="forgot-password-page"
            >
                <Helmet><title>Forgot Password</title></Helmet>
                <Col className="form-container">
                    <img
                        className="ivory-logo-img"
                        src={ivoryLogo}
                        alt=""
                    />
                    <Row className="forgot-form-container">
                        <Row className="form-header">
                            <span className="forgot-password-text">Forget Password?</span>
                            <span className="header-description">Please enter the email address associated with your account below.</span>
                        </Row>
                        <Form
                            className="forgot-form"
                            form={forgotPasswordForm}
                            onFinish={handleSendEmailButton}>
                            <Row className="forgot-form-field">
                                <span className="email-text">Email</span>
                                <Form.Item
                                    name="email">
                                    <Input
                                        className="login-form-input"
                                        label="email" />
                                </Form.Item>
                                <span className="error-message"></span>
                            </Row>
                            <Row className="buttons-container">
                                <Button
                                    className="send-button"
                                    htmlType="submit"
                                >
                                    Send
                                </Button>
                                <span className="go-back" onClick={() => {
                                    navigate("/login");
                                }}>Back to Log in</span>
                            </Row>
                        </Form>
                    </Row>
                </Col>
                <Col className="logo-container" >
                    <CCarousel indicators>
                        <CCarouselItem>
                            <Row className="logo-container-div">
                                <CImage className="ivory-login-bg" src={background} alt="" />
                                <CCarouselCaption className="ivory-description">
                                    <span className="description-header">
                                        AI-Powered Dental Assessments
                                    </span>
                                    <span className="description-content">
                                        Instantly analyze dental X-rays using advanced AI technology to
                                        identify potential issues, recommend treatments, and provide
                                        comprehensive reports.
                                    </span>
                                </CCarouselCaption>
                            </Row>
                        </CCarouselItem>
                        <CCarouselItem>
                            <Row className="logo-container-div">
                                <CImage className="ivory-login-bg" src={background} alt="" />
                                <CCarouselCaption className="ivory-description">
                                    <span className="description-header">
                                        AI-Powered Dental Assessments
                                    </span>
                                    <span className="description-content">
                                        Instantly analyze dental X-rays using advanced AI technology to
                                        identify potential issues, recommend treatments, and provide
                                        comprehensive reports.
                                    </span>
                                </CCarouselCaption>
                            </Row>
                        </CCarouselItem>
                        <CCarouselItem>
                            <Row className="logo-container-div">
                                <CImage className="ivory-login-bg" src={background} alt="" />
                                <CCarouselCaption className="ivory-description">
                                    <span className="description-header">
                                        AI-Powered Dental Assessments
                                    </span>
                                    <span className="description-content">
                                        Instantly analyze dental X-rays using advanced AI technology to
                                        identify potential issues, recommend treatments, and provide
                                        comprehensive reports.
                                    </span>
                                </CCarouselCaption>
                            </Row>
                        </CCarouselItem>
                    </CCarousel>
                </Col>
            </Row>
        </Spin>
    );
}

export default ForgotPassword;