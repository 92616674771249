import { createContext, useContext, useState } from "react";

const UserContext = createContext();

export const AuthContextProvider = ({ children }) => {
    const [spinner, setSpinner] = useState(false);

    // Custom function to handle API calls with spinner control
    const fetchWithSpinner = async (apiCall) => {
        try {
            setSpinner(true); // Show spinner
            const response = await apiCall(); // Execute the API call passed as a function
            return response; // Return the response for further processing
        } catch (error) {
            console.error("API Call Error:", error);
            throw error; // Propagate error to handle in the component
        } finally {
            setSpinner(false); // Hide spinner after the call is done
        }
    };

    return (
        <UserContext.Provider
            value={{
                spinner,
                setSpinner,
                fetchWithSpinner, // Add fetchWithSpinner to context
            }}
        >
            {children}
        </UserContext.Provider>
    );
};

export const UserAuth = () => {
    return useContext(UserContext);
};