export const common_constants_email = "email";
export const common_constants_password = "password";
export const common_constants_token = "Token";
export const common_constants_loginSuccess = "Login Successful";
export const common_constants_name = "name";

export const _status_PENDING = "PENDING";
export const _status_ACTIVE = "ACTIVE";
export const _status_SUBSCRIPTIONS = "SUBSCRIPTIONS"; 
export const _status_INACTIVE = "INACTIVE";
export const _status_LOCKED = "LOCKED" ;
export const _status_DELETED = "DELETED"; 
export const _status_TRAIL = "TRIAL";

export const tab_general = "geneal";
export const tab_users = "users";
export const tab_role = "role";
export const tab_access = "access";