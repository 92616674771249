import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import './App.css';
import Home from "./components/home/Home";
import { AuthContextProvider } from "./context_api/ContextApi";
import Login from "./login/Login";
import ProtectedRoute from "./protected-route/ProtectedRoute";
import ForgotPassword from "./login/forgot-password/ForgotPassword";
import Resend from "./login/resend/Resend";
import { routes_login } from "./utils/RoutingConsts";

function App() {
  return (
    <AuthContextProvider>
      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={<Navigate to="/login" />}></Route>
          <Route exact path={routes_login} element={<Login />}></Route>
          <Route exact path="/dashboard/*" element={<ProtectedRoute><Home /></ProtectedRoute>}></Route>
          <Route exact path="/forgot-password" element={<ForgotPassword />}></Route>
          <Route exact path="/resend" element={<Resend />}></Route>

        </Routes>
      </BrowserRouter>
    </AuthContextProvider>);
}

export default App;
